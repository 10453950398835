import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import {baseUrl } from "../../../comman/config";
import Select from 'react-select';
import { useNavigate, Link } from 'react-router-dom';
import Loader from '../../Loader';

export default function Form({detail}) {
   
    const [formData, setFormData] = useState({
        menuitem_id:'',
        menuitem_category : '',
        method: '',
        rm_to_fg_ratio:'',
        // rm_to_fg_ratio_ltrs:'',
        // rm_to_fg_ratio_nos: '',
        fg_consumption_per_person:'',
        // fg_consumption_per_person_ltrs: '',
        // fg_consumption_per_person_nos: '',
        rm_to_bowl_ratio :'',
        // rm_to_bowl_ratio_ltrs :'',
        // rm_to_bowl_ratio_nos :'',
        one_bowl_qty: '',
        avg_density:'',
        // one_bowl_qty_ltrs:'',
        // one_bowl_qty_nos:'',
        raw_mat_per_100_person: '',
        ras_raw_mat_per_100_person:'',
        avg_consumption_per_bowl:'',
        raw_atta_to_finished_atta_multiplier:0,
        conversion_checked:true
    });
    const [validationErrors, setValidationErrors] = useState({
        // menuitem_id : false,
        // menuitem_category : false,
        // method: false,
        // rm_to_fg_ratio:false,
        // rm_to_fg_ratio_ltrs: false,
        // rm_to_fg_ratio_nos:false,
        // fg_consumption_per_person:false,
        // fg_consumption_per_person_ltrs:false,
        // fg_consumption_per_person_nos:false,
        // rm_to_bowl_ratio :false,
        // rm_to_bowl_ratio_ltrs : false,
        // rm_to_bowl_ratio_nos : false,
        // one_bowl_qty: false,
        // one_bowl_qty_ltrs:false,
        // one_bowl_qty_nos:false,
        // raw_mat_per_100_person: false,
        // ras_raw_mat_per_100_person: false,
        // avg_consumption_per_bowl:false,
        // raw_atta_to_finished_atta_multiplier:false

    });
    const [menuitemList, setMenuitemList] = useState([]);
    const [menuitemCategory, setMenuitemCategory] = useState([]);
    const [methodList, setMethodList] = useState([]);
    const [popupOpen , setPopupOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const formRef = useRef(null);
    
    
    const navigate = useNavigate();
    useEffect(() => {
        getMenuitemList();
        getMenuitemCategory();
        getMethodList();
    }, []);

    useEffect(() => {
        if(detail && methodList){
            setLoading(true)
            const menuitemId = detail[0].menu_item_id;
            const menuItem = menuitemList.find(item => item.id === menuitemId);
            const menuItemObject = menuItem ? { value: menuItem.id, label: `${menuItem.item_name} (${menuItem.eng_name})`, eng_name: menuItem.eng_name, category : menuItem.category }: { value: '', label: '', eng_name : '', category :'' };

            const methodId = detail[0].method;
            const methodItem = methodList.find(item => item.name == methodId);

            const methodObject = methodItem ? { value: methodItem.id, label: methodItem.name }: { value: '', label: '' };
            console.log(methodId);
            setFormData({
                menuitem_id: menuItemObject || '',
                menuitem_category: detail[0].menu_category_id || '',
                method: methodObject || '',
                rm_to_fg_ratio: detail[0].rm_to_fg_ratio || '',
                // rm_to_fg_ratio_ltrs: detail[0].rm_to_fg_ratio_ltrs ||'',
                // rm_to_fg_ratio_nos : detail[0].rm_to_fg_ratio_nos || '',
                fg_consumption_per_person: detail[0].fg_consumption_per_person || '',
                // fg_consumption_per_person_ltrs: detail[0].fg_consumption_per_person_ltrs || '',
                // fg_consumption_per_person_nos: detail[0].fg_consumption_per_person_nos || '',
                rm_to_bowl_ratio : detail[0].rm_to_bowl_ratio || '',
                // rm_to_bowl_ratio_ltrs : detail[0].rm_to_bowl_ratio_ltrs || '',
                // rm_to_bowl_ratio_nos : detail[0].rm_to_bowl_ratio_nos || '',
                one_bowl_qty:  detail[0].one_bowl_qty || '',
                avg_density: detail[0].avg_density || '',
                // one_bowl_qty_ltrs: detail[0].one_bowl_qty_ltrs || '',
                // one_bowl_qty_nos: detail[0].one_bowl_qty_nos || '',
                raw_mat_per_100_person: detail[0].raw_mat_per_100_person || '',
                ras_raw_mat_per_100_person: detail[0].ras_raw_mat_per_100_person || '',
                avg_consumption_per_bowl: detail[0].avg_consumption_per_bowl || '',
                raw_atta_to_finished_atta_multiplier: detail[0].raw_atta_to_finished_atta_multiplier || '',
                conversion_checked:true
            });
            setLoading(false)
        }
        // console.log(detail[0]);
    }, [detail, methodList, menuitemList]);

    const getMenuitemList = async () => {
        const response = await axios.get(`${baseUrl}/item/list`);
        const list = response.data.data.filter((item => item.status == 1));
        setMenuitemList(list);
    }

    const getMenuitemCategory = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=item_category_master&condition=status=1`);
        setMenuitemCategory(response.data.data);
    }
    
    const getMethodList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=method_master`);
        if(!detail){
            // setMethodList(response.data.data.filter((item) => item.id != 2));
            setMethodList(response.data.data);
        }else{
            setMethodList(response.data.data);
        }
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        // let newValidationErrors = {
        //     menuitem_id: formData.menuitem_id === '',
        //     menuitem_category : formData.menuitem_category === '',
        //     method: formData.method === '',
        //     rm_to_fg_ratio : formData.rm_to_fg_ratio === '',
        //     // rm_to_fg_ratio_ltrs: formData.rm_to_fg_ratio_ltrs === '',
        //     // rm_to_fg_ratio_nos : formData.rm_to_fg_ratio_nos  === '',
        //     fg_consumption_per_person:formData.fg_consumption_per_person === '',
        //     // fg_consumption_per_person_ltrs:formData.fg_consumption_per_person_ltrs === '',
        //     // fg_consumption_per_person_nos:formData.fg_consumption_per_person_nos === '',
        //     rm_to_bowl_ratio :formData.rm_to_bowl_ratio === '',
        //     // rm_to_bowl_ratio_ltrs : formData.rm_to_bowl_ratio_ltrs === '',
        //     // rm_to_bowl_ratio_nos : formData.rm_to_bowl_ratio_nos === '',
        //     one_bowl_qty: formData.one_bowl_qty === '',
        //     // one_bowl_qty_ltrs:formData.one_bowl_qty_ltrs === '',
        //     // one_bowl_qty_nos:formData.one_bowl_qty_nos === '',
        //     raw_mat_per_100_person: formData.raw_mat_per_100_person === '',
        //     ras_raw_mat_per_100_person: formData.ras_raw_mat_per_100_person === '',
        //     avg_consumption_per_bowl:formData.avg_consumption_per_bowl === '',
        //     // raw_atta_to_finished_atta_multiplier:formData.raw_atta_to_finished_atta_multiplier === ''
        // };
        // // if(meth)
        // setValidationErrors(newValidationErrors);
        // const hasErrors = Object.values(newValidationErrors).some((error) => error);
        // if (hasErrors) {
            
        //     // Find the first invalid input element
        //     const firstInvalidInput = formRef.current.querySelector('.invalid');
        //     if (firstInvalidInput) {
        //       // Scroll to the first invalid input element
        //       firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
        //     }
        // }else {
            let url;
            try {
                setPopupOpen(true);                        
                if(detail){
                    url = `consumption_master/edit/${detail[0].id}`;
                }else{
                    url = "consumption_master/add";
                }
                const response = await axios.post(`${baseUrl}/${url}`,{
                    formData: formData,
                });
                setPopupOpen(false);
                if(response.data.status == true){
                    navigate("/consumption_master/list");
               }else{
                setValidationErrors({error : response.data.msg});
               }
                
            } catch (error) {

                setPopupOpen(false);
                console.log(error);
                
            }
        // }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
            setFormData((prevData) => {
                const updatedData = {
                    ...prevData,
                    [name]: value,
                };
                if (name === 'rm_to_fg_ratio' || name === 'one_bowl_qty') {
                    const rm_to_fg_ratio = updatedData.rm_to_fg_ratio || 0;
                    const one_bowl_qty = updatedData.one_bowl_qty; // Avoid division by zero
                    updatedData.rm_to_bowl_ratio = one_bowl_qty > 0 ? (rm_to_fg_ratio / one_bowl_qty).toFixed(2) : 0;
                }
                if (name === 'fg_consumption_per_person' || name === 'one_bowl_qty') {
                    const fg_consumption_per_person = updatedData.fg_consumption_per_person || 0;
                    const one_bowl_qty = updatedData.one_bowl_qty; // Avoid division by zero
                    updatedData.avg_consumption_per_bowl = fg_consumption_per_person > 0 ? (one_bowl_qty / fg_consumption_per_person).toFixed(2) : 0;
                }
                if (name === 'fg_consumption_per_person' || name === 'rm_to_fg_ratio') {
                    const fg_consumption_per_person = updatedData.fg_consumption_per_person || 0;
                    const rm_to_fg_ratio = updatedData.rm_to_fg_ratio; // Avoid division by zero
                    updatedData.raw_mat_per_100_person = fg_consumption_per_person > 0 ? ((fg_consumption_per_person / rm_to_fg_ratio) * 100).toFixed(2) : 0;
                }
                return updatedData;
            });
      
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };

    const handleSelectChange = (data, option) => {
        if(option.name == 'menuitem_id'){
            console.log(data);
            setFormData((prevData) => ({
                ...prevData,
                menuitem_id : data,
                menuitem_category: data.category.id
            }));
        }else{
            setFormData((prevData) => ({
                ...prevData,
                [option.name] : data,
               
            }));
        }
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [option.name]: false
        }));
    }

    useEffect(() => {
        if(formData.method){
            console.log(formData.method);
        }
    }, [formData.method]);
    const customStyles = {
        control: (provided) => ({
            ...provided,
            minWidth: '180px',  
            maxWidth: '100%',  
             
        }),
        menu: (provided) => ({
            ...provided,
            minWidth: '180px',  
            
            maxWidth: '100%',
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: "0.80rem",
        }),
    };

    const setConversionChecked = (e) => {
        console.log(e.target.checked)
        setFormData((prevData) => {
            const updatedData = {
                ...prevData,
                conversion_checked: e.target.checked,
            };
            return updatedData
        });
    }


    useEffect(()=>{
        console.log(formData)
    },[formData])
  return (
 
 <form ref={formRef}>
    {loading && <Loader/>}
                <div className="space-y-12">
                {validationErrors.error && <div className="text-red-500">Please check consumption master allready exist for this menu item.</div>}
                    <div>
                    <div className="mt-10 space-y-8 lg:space-y-0 lg:flex lg:gap-x-5 lg:flex-wrap">
    <div className="sm:col-span-2">
        <label htmlFor="menuitem_eng" className="block text-sm font-medium leading-6 text-gray-900">
            Menuitem Name (Eng)
        </label>
        <div className="mt-2">
            <Select
                classNamePrefix="select"
                value={formData.menuitem_id}
                name="menuitem_id"
                onChange={(selectedOption, name) => {
                    handleSelectChange(selectedOption, name);
                }}
                styles={customStyles}
                options={menuitemList.map(item => ({
                    value: item.id,
                    label: `${item.item_name} (${item.eng_name})`,
                    eng_name: item.eng_name,
                    category: item.category
                }))}
                isDisabled={detail}
                filterOption={(option, inputValue) => {
                    return option.data.label.toLowerCase().includes(inputValue.toLowerCase()) ||
                        option.data.eng_name.toLowerCase().includes(inputValue.toLowerCase());
                }}
            />   
        </div>
        {validationErrors.menuitem_id && <div className="text-red-500">Please enter an English name.</div>}
    </div>
    <div className="sm:col-span-2">
        <label htmlFor="menuitem_category" className="block text-sm font-medium leading-6 text-gray-900">
            Menuitem Category
        </label>
        <div className="mt-2">
            <select     
                id="menuitem_category"
                name="menuitem_category"
                disabled
                value={formData.menuitem_category}
                className={`block rounded-md w-full lg:w-52 border ${validationErrors.menuitem_category ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}>
                <option value="">Select a Category</option>
                {menuitemCategory.map((item) => (
                    <option value={item.id} key={item.id}>{item.name}</option>
                ))}
            </select>
        </div>
    </div>
</div>
                        
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="method" className="block text-sm font-medium leading-6 text-gray-900">Method</label>
                                <div className="mt-2">
                                <Select
                                        classNamePrefix="select"
                                        value={formData.method}
                                        name="method"
                                        styles={{
                                            placeholder: (provided) => ({
                                              ...provided,
                                              fontSize: "0.80rem",
                                            }),
                                         
                                          }}
                                          placeholder="Select method"
                                        onChange={(selectedOption, name) => {
                                            handleSelectChange(selectedOption, name);
                                        }}
                                        options={methodList.map(item => ({
                                            value: item.id,
                                            label: item.name
                                        }))}
                                        isDisabled={detail}
                                        filterOption={(option, inputValue) => {
                                            return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                                        }}
                                    />   
                                </div>
                                {validationErrors.method && <div className="text-red-500">Please select a method.</div>}
                            </div>
                            {(formData.method?.value == 1 || formData.method?.value == 4) && <div className="sm:col-span-2 flex items-end mb-2">
                                    <label>
                                        <input
                                        type="checkbox"
                                        name='kgs_to_ltrs'
                                        className="mr-2 "
                                        checked={formData.conversion_checked}
                                        onChange={(e) => { setConversionChecked(e)}}
                                        />
                                        {formData.method?.value == 4 ? "Convert to Readymade - Ltrs" :"Convert to Ltrs"}
                                    </label>
                            </div>}
                            {(formData.method?.value == 2 || formData.method?.value == 5) && <div className="sm:col-span-2 flex items-end mb-2">
                                    <label>
                                        <input
                                        type="checkbox"
                                        name='ltrs_to_kgs'
                                        className="mr-2 "
                                        checked={formData.conversion_checked}
                                        onChange={(e) => { setConversionChecked(e)}}
                                        />
                                        {formData.method?.value == 5 ? "Convert to Readymade - Kgs" :"Convert to Kgs"}
                                    </label>
                            </div>}
                        </div>
                        
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="rm_to_fg_ratio" className="block text-sm font-medium leading-6 text-gray-900">RM to FG Ratio ({formData.method.label})</label>
                                <div className="mt-2">
                                <input
                                    id="rm_to_fg_ratio"
                                    name="rm_to_fg_ratio"
                                    type="number" 
                                    step="0.01"
                                    placeholder='RM to FG Ratio'
                                    value={formData.rm_to_fg_ratio}
                                    onChange={handleInputChange}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                                    className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                />
                                </div>
                                {validationErrors.rm_to_fg_ratio && <div className="text-red-500">Please enter a rm_to_fg_ratio.</div>}
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="rm_to_bowl_ratio" className="block text-sm font-medium leading-6 text-gray-900">RM to Bowl Ratio ({formData.method.label})</label>
                                <div className="mt-2">
                                <input
                                    id="rm_to_bowl_ratio"
                                    name="rm_to_bowl_ratio"
                                    type="number" 
                                    step="0.01"
                                    placeholder='RM to Bowl Ratio'
                                    value={formData.rm_to_bowl_ratio}
                                    onChange={handleInputChange}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                                    className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                />
                                </div>
                                {validationErrors.rm_to_bowl_ratio && <div className="text-red-500">Please enter a rm_to_bowl_ratio.</div>}
                            </div>

                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="fg_consumption_per_person" className="block text-sm font-medium leading-6 text-gray-900">FG per Person ({formData.method.label})</label>
                                <div className="mt-2">
                                <input
                                    id="fg_consumption_per_person"
                                    name="fg_consumption_per_person"
                                    type="number" 
                                    step="0.01"
                                    placeholder='FG per Person'
                                    value={formData.fg_consumption_per_person}
                                    onChange={handleInputChange}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                                    className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                />
                                </div>
                                {validationErrors.fg_consumption_per_person && <div className="text-red-500">Please enter a fg_consumption_per_person.</div>}
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="avg_consumption_per_bowl " className="block text-sm font-medium leading-6 text-gray-900">Persons per Bowl</label>
                                <div className="mt-2">
                                <input
                                    id="avg_consumption_per_bowl"
                                    name="avg_consumption_per_bowl"
                                    type="number" 
                                    step="0.01"
                                    placeholder='Persons per Bowl'
                                    value={formData.avg_consumption_per_bowl}
                                    onChange={handleInputChange}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                                    className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                />
                                </div>
                                {validationErrors.avg_consumption_per_bowl && <div className="text-red-500">Please enter a avg_consumption_per_bowl.</div>}
                            </div>
                        </div>
                        
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="one_bowl_qty" className="block text-sm font-medium leading-6 text-gray-900">One Bowl QTY ({formData.method.label})</label>
                                <div className="mt-2">
                                <input
                                    id="one_bowl_qty"
                                    name="one_bowl_qty"
                                    type="number" 
                                    step="0.01"
                                    placeholder='One Bowl QTY'
                                    value={formData.one_bowl_qty}
                                    onChange={handleInputChange}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                                    className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                />
                                </div>
                                {validationErrors.one_bowl_qty && <div className="text-red-500">Please enter a one_bowl_qty.</div>}
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="raw_mat_per_100_person" className="block text-sm font-medium leading-6 text-gray-900">RM per 100 Person</label>
                                <div className="mt-2">
                                <input
                                    id="raw_mat_per_100_person"
                                    name="raw_mat_per_100_person"
                                    type="number" 
                                    step="0.01"
                                    placeholder='RM per 100 Person'
                                    value={formData.raw_mat_per_100_person}
                                    onChange={handleInputChange}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                                    className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                />
                                </div>
                                {validationErrors.raw_mat_per_100_person && <div className="text-red-500">Please enter a raw_mat_per_100_person.</div>}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="avg_density" className="block text-sm font-medium leading-6 text-gray-900">Avg Density</label>
                                <div className="mt-2">
                                <input
                                    id="avg_density"
                                    name="avg_density"
                                    type="number" 
                                    step="0.01"
                                    placeholder='Avg Density'
                                    value={formData.avg_density}
                                    onChange={handleInputChange}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                                    className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                />
                            </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="ras_raw_mat_per_100_person" className="block text-sm font-medium leading-6 text-gray-900">STD RM per 100 Person</label>
                                <div className="mt-2">
                                <input
                                    id="ras_raw_mat_per_100_person"
                                    name="ras_raw_mat_per_100_person"
                                    type="number" 
                                    step="0.01"
                                    placeholder='STD RM per 100 Person'
                                    value={formData.ras_raw_mat_per_100_person}
                                    onChange={handleInputChange}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                                    className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                />
                                </div>
                                {validationErrors.ras_raw_mat_per_100_person && <div className="text-red-500">Please enter a ras_raw_mat_per_100_person.</div>}
                            </div>
                        </div>
                       
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="raw_atta_to_finished_atta_multiplier" className="block text-sm font-medium leading-6 text-gray-900">Raw atta to finished atta multiplier</label>
                                <div className="mt-2">
                                <input
                                    id="raw_atta_to_finished_atta_multiplier"
                                    name="raw_atta_to_finished_atta_multiplier"
                                    type="number" 
                                    step="0.01"
                                    placeholder='Raw atta to finished atta multiplier'
                                    value={formData.raw_atta_to_finished_atta_multiplier}
                                    onChange={handleInputChange}
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                                    className="block w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                />
                                </div>
                                {validationErrors.raw_atta_to_finished_atta_multiplier && <div className="text-red-500">Please enter a raw_atta_to_finished_atta_multiplier.</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <Link to="/consumption_master/list">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                    </Link>
                    <button
                    onClick={handleSubmit}
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Save
                    </button>
                </div>
            </form>
            )
        }