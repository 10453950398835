
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import {baseUrl, limit} from "../../comman/config";
import * as XLSX from 'xlsx';


import { DateSessionReportFilter } from "../../components/Filter/DateSessionReportFilter";
import Loader from '../../components/Loader';

function List() {

    const today = new Date().toISOString().split("T")[0];    
    const [filteredList, setFilteredList] = useState([]);
    const [reportList, setReportList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [popupOpen, setPopupOpen] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [session, setSession] = useState("");
    const [item, setItem] = useState("");
    const [method, setMethod] = useState("");
    const [summary, setSummary] = useState("");

    useEffect(() => {
        getShortageList();
    }, []);

    useEffect(() => {
        getShortageList(); 
    }, [fromDate,toDate, session, item, method]);


    useEffect(() => {
        let filterelist;
        // console.log(method);
        if(method && method.length > 0){
            const methodIds = method.map(method => method.value);
            filterelist = reportList.filter((item) => methodIds.includes(item.measurements));
        } else{
            filterelist = reportList;
        }  
        setFilteredList(filterelist);
    }, [method, reportList]);
    const getShortageList = async () => {
        
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/reports/master_report?page=${page}&limit=${limit}&fromDate=${fromDate}&toDate=${toDate}&session=${JSON.stringify(session)}&item=${JSON.stringify(item)}&method=${JSON.stringify(method)}`, {
           
        });
        setPopupOpen(false);
        setReportList(response.data.data);
        setFilteredList(response.data.data);
        setTotalItem(response.data.totalItem);
    }

    const handelFilterChange= (fromDate, toDate, session, item, method) => {
        console.log(summary);
        setFromDate(fromDate);
        setToDate(toDate);
        setSession(session);
        setItem(item);
        setMethod(method);
        
        
    };

    const hadelSummary = (summary) => {
        setSummary(summary);
    }
   
    // const handleExport = () => {
    //     const header = ["Date", "Session", "Menu Item", "Measure Method", "Estimated", "Production","Pass Issued", "Dish Issued", "Planned", "Actual", "Planned", "Actual","Bowl", "FG", "RM", "STD.","Planned", "Actual", "Std.", "Actual", "Std.", "Actual", "Std.", "Actual", "Std.", "Actual" , "Std.", "Actual", "Std.", "Live"];
    //     const extraHeader = ["", "", "", "", "","", "Count", "","RAS -", "RM Consumption","", "Raw Qty", "", "Finished Qty", "","", "Excess Qty",  "Per Person", "FG Consumption", "Per Person", "RM To FG Ratio", "","Raw Material To", "Bowl ratio", "Avg Person", "Per Bowl", "1 Bowl Qty", "", "Density", ""];
    //     const unitheader = ["", "", "", "", "Persons", "Persons", "Persons", "Persons","","", "", "Kgs.", "Kgs.", "Kgs.", "Kgs.","Nos.", "Kgs.", "Kgs.",  "Kgs.", "Kgs.", "", "", "", "", "Persons", "Persons" , "Kgs.", "Kgs.", "", ""];
    //     const csv = [extraHeader, header, unitheader].concat(
    //         filteredList.filter(item => item.summary).map((item, index) => {
    //             let resultArray = [];
    //             if(item.summary){
    //             resultArray.push(formatDate(item.date));
    //             resultArray.push(item.session_name);
    //             resultArray.push(`${item.item_name} (${item.eng_name})`);
    //             resultArray.push(item.methodName?.name ?? '-');
    //             resultArray.push(item.raw_material?.estimated_count);
    //             resultArray.push(item.food_qty ?? 0);
    //             resultArray.push(item.pass_issued ?? 0);
    //             resultArray.push(item.final_dish_count ?? 0);
    //             resultArray.push(item.raw_material.ras_qty_per_100_person);
    //             resultArray.push(item.consumption_master?.ras_raw_mat_per_100_person ?? 0);
    //             resultArray.push(item.raw_mat_per_100_person ?? 0);
    //             resultArray.push(item.raw_material.planned_rm_qty);
    //             resultArray.push(item.batch_raw_material_qty);
    //             resultArray.push(item.std_fg_qty);
    //             resultArray.push(item.finishedQty ?? 0);
    //             resultArray.push(item.excess_bowl_qty ?? 0);
    //             resultArray.push(item.excess_fg_qty ?? 0);
    //             resultArray.push(item.excess_rm_qty ?? 0);
    //             resultArray.push(item.std_fg_consumption_per_person ?? 0);
    //             resultArray.push(item.fg_consumption_per_person ?? 0);
    //             resultArray.push(item.consumption_master?.rm_to_fg_ratio ?? 0);
    //             resultArray.push(item.current_rm_to_fg_ratio ?? 0);
    //             resultArray.push(item.std_rm_to_bowl_ratio ?? 0);
    //             resultArray.push(item.current_rm_to_bowl_ratio);
    //             resultArray.push(item.std_avg_person_per_bowl ?? 0);
    //             resultArray.push(item.person_per_bowl ?? 0);
    //             resultArray.push(item.std_one_bowl_qty ?? 0);
    //             resultArray.push(item.no_of_rotis_in_1_bowl_icebox ?? 0);
    //             resultArray.push(item.std_density ?? 0);
    //             resultArray.push(item.density ?? "-");}
    //             return resultArray.join(',');
    //         })
    //     ).join('\n');
    //     const blob = new Blob([csv], { type: 'text/csv' });
    //     const url = URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.setAttribute('href', url);
    //     a.setAttribute('download', "summary_report");
    //     a.click();
    // }

    const handleExport = () => {
        const header = ["Date", "Session", "Menu Item", "Measure Method", "Estimated", "Production", "Pass Issued", "Dish Issued", "Planned", "Actual", "Planned", "Actual", "Bowl", "FG", "RM", "STD.", "Planned", "Actual", "Std.", "Actual", "Std.", "Actual", "Std.", "Actual", "Std.", "Actual", "Std.", "Actual", "Std.", "Live"];
        const extraHeader = ["", "", "", "", "", "", "Count", "", "RAS -", "RM Consumption", "", "Raw Qty", "", "Finished Qty", "", "", "Excess Qty", "Per Person", "FG Consumption", "Per Person", "RM To FG Ratio", "", "Raw Material To", "Bowl ratio", "Avg Person", "Per Bowl", "1 Bowl Qty", "", "Density", ""];
        const unitHeader = ["", "", "", "", "Persons", "Persons", "Persons", "Persons", "", "", "", "Kgs.", "Kgs.", "Kgs.", "Kgs.", "Nos.", "Kgs.", "Kgs.", "Kgs.", "Kgs.", "", "", "", "", "Persons", "Persons", "Kgs.", "Kgs.", "", ""];

        // Create a 2D array of headers and data
        const data = [extraHeader, header, unitHeader];

        // Add rows of data from `filteredList`
        filteredList.filter(item => item.summary).forEach(item => {
            const resultArray = [
                formatDate(item.date),
                item.session_name,
                `${item.item_name} (${item.eng_name})`,
                item.methodName?.name ?? '-',
                item.raw_material?.estimated_count,
                item.food_qty ?? 0,
                item.pass_issued ?? 0,
                item.final_dish_count ?? 0,
                item.raw_material.ras_qty_per_100_person,
                item.consumption_master?.ras_raw_mat_per_100_person ?? 0,
                item.raw_mat_per_100_person ?? 0,
                item.raw_material.planned_rm_qty,
                item.batch_raw_material_qty,
                item.std_fg_qty,
                item.finishedQty ?? 0,
                item.excess_bowl_qty ?? 0,
                item.excess_fg_qty ?? 0,
                item.excess_rm_qty ?? 0,
                item.std_fg_consumption_per_person ?? 0,
                item.fg_consumption_per_person ?? 0,
                item.consumption_master?.rm_to_fg_ratio ?? 0,
                item.current_rm_to_fg_ratio ?? 0,
                item.std_rm_to_bowl_ratio ?? 0,
                item.current_rm_to_bowl_ratio,
                item.std_avg_person_per_bowl ?? 0,
                item.person_per_bowl ?? 0,
                item.std_one_bowl_qty ?? 0,
                item.no_of_rotis_in_1_bowl_icebox ?? 0,
                item.std_density ?? 0,
                item.density ?? "-"
            ];
            data.push(resultArray);
        });

        // Create a new workbook and worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Summary Report");

        // Export to XLSX file
        XLSX.writeFile(workbook, `Summary_Report_${fromDate ? '_' + formatDate(fromDate) : ''}to${toDate ? '_' + formatDate(toDate) : ''}${session?.label ? '_' + session.label : ''}.xlsx`);
    };

    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).format(date);
      };
      


    return (
        <div className="p-4">
            {popupOpen && <Loader/>}
            <div className="flex justify-between items-center py-4 px-6">
                <h1 className="text-2xl font-bold">Summary Report</h1>
                <div>
                    <Link to="/">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Back</button>
                    </Link>
                </div>
            </div>
            <div className="relative shadow-md sm:rounded-lg">
            <DateSessionReportFilter 
            handleFilterChange={handelFilterChange} 
            hadelSummary={hadelSummary} 
            handleExport={handleExport} 
    
            />


                <div className="overflow-x-auto">
                <table className="w-full  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            
                            <th scope="col" className="px-2 py-1 border border-black text-center "></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" colSpan={4} className="px-4 py-3 border border-black text-center">Count</th>
                            <th scope="col" colSpan={4} className="px-2 py-1 border border-black text-center bg-red-400 ">RAS - RM consumption per 100 person</th>
                            <th scope="col" colSpan={2} className="px-2 py-1 border border-black text-center ">Raw Qty.</th>
                            <th scope="col" colSpan={2} className="px-2 py-1 border border-black text-center ">Finished Qty.</th>
                            <th scope="col" colSpan={3} className="px-2 py-1 border border-black text-center ">Excess Qty.</th>
                            <th scope="col" colSpan={2} className="px-2 py-1 border border-black text-center ">FG consumption per person</th>
                            <th scope="col" colSpan={2} className="px-2 py-1 border border-black text-center ">RM to FG Ratio</th>
                            <th scope="col" colSpan={2} className="px-2 py-1 border border-black text-center ">Raw Material to Bowl ratio</th>
                            <th scope="col" colSpan={2} className="px-2 py-1 border border-black text-center ">Avg persons per bowl</th>
                            <th scope="col" colSpan={2} className="px-2 py-1 border border-black text-center ">1 Bowl Qty.</th>
                            <th scope="col" colSpan={2} className="px-2 py-1 border border-black text-center ">Density</th>
                        </tr>
                        <tr>
                        
                            <th scope="col" className="px-2 py-1 border border-black text-center">Date</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Session</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Menu Item</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Measure Method</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Maharaj Name</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Estimated</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Production</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Pass Issued</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Dish Issued</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center bg-red-400">Std.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center bg-red-400">Planned</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center bg-red-400">Actual</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center bg-red-400">Production</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Planned</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Actual</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Planned</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Actual</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Bowl</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">FG</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">RM</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Std.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Actual</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Std.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Actual</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Std.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Actual</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Std.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Actual</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Std.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Actual</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Std.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Live</th>
                        </tr>
                        <tr>    
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Persons</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Persons</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Persons</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Persons</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Kgs.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Kgs.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Kgs.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Kgs.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Nos.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Kgs.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Kgs.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Kgs.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Kgs.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Persons</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Persons</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Kgs.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Kgs.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                            <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                    {filteredList.filter(item => item.summary).map((item, index) => (
                       
                         <tr key={index} className={`${item.summary ? 'bg-lime-200' : (item.methodName?.id == 1 ? 'bg-blue-200' : (item.methodName?.id == 2 ? 'bg-orange-200' : 'bg-white dark:bg-gray-800 dark:border border-black-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 '))} border border-black-b`}>
                            <td className="px-2 py-1 border border-black text-center">{formatDate(item.date)}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.session_name}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.eng_name}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.methodName?.name ?? '-'}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.maharaj?.maharaj_name ?? '-'}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.raw_material?.estimated_count ?? 0).toFixed(0)}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.food_qty ?? 0).toFixed(0)}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.pass_issued ?? 0).toFixed(0)}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.final_dish_count ?? 0).toFixed(0)}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.consumption_master?.ras_raw_mat_per_100_person ?? 0).toFixed(2) }</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.raw_material.ras_qty_per_100_person ?? 0).toFixed(2)}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.raw_mat_per_100_person ?? 0).toFixed(2)}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.production_ras ?? 0).toFixed(2)}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.raw_material.planned_rm_qty ?? 0).toFixed(0)}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.batch_raw_material_qty ?? 0).toFixed(0)}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.std_fg_qty}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.finishedQty ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.excess_bowl_qty ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.excess_fg_qty ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.excess_rm_qty ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.std_fg_consumption_per_person ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.fg_consumption_per_person ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.consumption_master?.rm_to_fg_ratio ?? 0).toFixed(2)}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.current_rm_to_fg_ratio  ?? 0).toFixed(2)}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.std_rm_to_bowl_ratio  ?? 0).toFixed(2)}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.current_rm_to_bowl_ratio ?? 0).toFixed(2)}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.std_avg_person_per_bowl ?? 0).toFixed(0)}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.person_per_bowl ?? 0).toFixed(0)}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.std_one_bowl_qty ?? 0).toFixed(2)}</td>
                            <td className="px-2 py-1 border border-black text-center">{Number(item.no_of_rotis_in_1_bowl_icebox ?? 0).toFixed(2)}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.std_density ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.density ?? "-"}</td>


                            
                        </tr>))}
           
                    </tbody>
                </table>
                </div>
            </div>
        </div>
  );
}

export default List;