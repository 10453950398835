import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import {baseUrl } from "../../comman/config";
import { useLocation } from 'react-router-dom';

const EventDateSessionFilter = ({ handleFilterChange }) => {
    const today = new Date().toISOString().split('T')[0];
    const [event, setEvent] = useState({});
    const [fromDate, setFromDate] = useState(today);
    const [toDate, setToDate] = useState(today);
    const [session, setSession] = useState([]);
    const [method, setMethod] = useState([]);
    const [filter, setFilter] = useState(false)

    const [sessionList, setSessionList] = useState([]);
    const [methodList, setMethodList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const location = useLocation();
    const routeName = location.pathname

    useEffect(() => {
        getEventList()
        getSessionList();
        getMethodList()
    }, []);

    const getEventList = async() => {
        const response = await axios.get(`${baseUrl}/comman/list?table=event_master&condition=status=1`);
        const data = response.data.data;
        const newObject = { id: 'all', event_name: 'All' };
        data.unshift(newObject);
        setEventList(data);
        setEvent({ value: 'all', label: 'All' })
    }

    const getSessionList =  async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=session_master&condition=status=1`,
            { headers: { 'Cache-Control': 'no-cache' } }
        );
        setSessionList(response.data.data);
    }

    const getMethodList = async () => {
        const response = await axios.get(
          `${baseUrl}/comman/list?table=method_master&condition=status=1`
        );
        setMethodList(response.data.data);
      };

    const handleDateChange = (e) => {
        const {name, value} = e.target;
        if(name == "from_date"){
            setFromDate(value);
        }else if(name == "to_date"){
            setToDate(value);
        }
        
    };
    // const handleDateChange = (e) => {
    //     setDate(e.target.value);
    // };

    const handleSelectChange = (data, option) => {
        setSession(data);
    };

    const handleChangeMultiSelect = (selected, name) => {
        if(name === 'method'){
            setMethod(selected);
        }
    };

    const handelEventChange = (item) => {
        // console.log(eventList);
        const data = eventList.filter(i => i.id == item.value);
        setEvent({value: data[0].id, label: data[0].event_name});
        // console.log(data[0].start_date);
        setFromDate(data[0].start_date);
        setToDate(data[0].end_date);
        console.log(data[0].start_date, data[0].end_date,)
        handleFilterChange(data[0].start_date, data[0].end_date, session);
    }

    const handleClear = () => {
        handleFilterChange(today,today);
        setFromDate(today);
        setToDate(today)
        setSession("");
        setFilter(false);
    }

    const handleSubmit = () => {
        console.log(fromDate,toDate,session)
        handleFilterChange(fromDate,toDate,session);
        setFilter(true);
    };

    return (
        <div className="pb-4 flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center">
            {routeName == "/meal_master/list" && <div className='m-5'>
                <Select
                        
                        classNamePrefix="select"
                        className='basic-multi-select w-36'
                        value={event}
                        name="event_name"
                        onChange={(selectedOptions) => handelEventChange(selectedOptions)}
                        options={eventList.map(item => ({
                            value: item.id,
                            label: item.event_name
                        }))}
                        // menuPortalTarget={document.body}
                        placeholder="Event List"
                        filterOption={(option, inputValue) => {
                            return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                        }}
                    />  
            </div>}
            <div className='m-5'>
                <input
                    type="date"
                    className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="from_date"
                    onChange={handleDateChange}
                    value={fromDate}
                />
            </div>
            <div className='m-5'>
                <input
                    type="date"
                    className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="to_date"
                    onChange={handleDateChange}
                    value={toDate}
                />
            </div>
            <div className='m-5'>
                <Select
                    isMulti
                    classNamePrefix="select"
                    value={session}
                    name="session"
                    onChange={(selectedOption, name) => {
                        handleSelectChange(selectedOption, name);
                    }}
                    options={sessionList.map(item => ({
                        value: item.id,
                        label: item.name
                    }))}
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />  
            </div>
            <div className='m-5'>
                <button
                    onClick={handleSubmit}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                    Submit
                </button>
                {filter && 
                 <button
                 onClick={handleClear}
                 className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
             >
                 Clear
             </button>}
            </div>
        </div>
    );
};

export {EventDateSessionFilter};
