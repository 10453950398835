import React, { useState, useEffect } from "react";
import axios from "axios";
import { saveAs } from 'file-saver';
import { baseUrl, getDateWithDay } from "../../comman/config.js";
import { MainRequirementFilter } from "../../components/Filter/MainRequirementFilter.js";
import toast, { Toaster } from "react-hot-toast";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import Loader from '../../components/Loader'
import IssueModal from "../../components/IssueModal.js";
import Select from 'react-select';

// import Pagination from "react-js-pagination";

function IssueList() {
  const today = new Date().toISOString().split("T")[0];
  const [requirementList, setRequirementList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [session, setSession] = useState({});
  const [issueStatus, setIssueStatus] = useState([]);
  const [item, setItem] = useState({});
  const [kit,setKit] = useState({})
  const [kitList,setKitList] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemIngredientList, setItemIngredientList] = useState([]);
  const [methodList, setMethodList] = useState([]);
  const [ingredientList, setIngredientList] = useState([]);
  const [loading, setLoading] = useState(false)


  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    getMethodList()
  }, []);

  useEffect(()=>{
    getIngredientList()
  },[itemIngredientList])

  useEffect(() => {
   
    if(fromDate && toDate){
      getKitList();
    }
  }, [fromDate, toDate, session, item, issueStatus])

  useEffect(()=>{
    if(activeIndex !== null){
      getKitItemsList()
    }
  },[kit,item,activeIndex])

  const handelFilterChange = async (from_date, to_date, session, item, issueStatus) => {
        setActiveIndex(null)
        setFromDate(from_date);
        setToDate(to_date);
        setSession(session);
        setItem(item);
        setIssueStatus(issueStatus)
  };

  const getIngredientList = async () => {
    const response = await axios.get(`${baseUrl}/ingredients/list?limit=all`);
    const existingIngredientIds = itemIngredientList?.map(item => item.ingredient_id) || [];
    const list = response.data.data.filter((item) => item.status == 1 && item?.store?.id == 1 && !existingIngredientIds.includes(item.id));
    // console.log(list)
    setIngredientList(list);
  };

  const getMethodList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=method_master&condition=status=1`
    );
    setMethodList(response.data.data);
  };

  const getKitItemsList = async ()=>{
    setLoading(true);
    const kit = kitList.find((item, index) => index == activeIndex);
    // console.log(kit)
    const condition = `pr.menu_item_id='${kit.menu_item_id}' AND pr.date = '${kit.date}' AND pr.session='${kit.session}' AND pr.batch = '${kit.batch}' AND im.store = 1`;
    const RecipeDbData = await axios.get(`${baseUrl}/main_store_issue/ingredient_list?condition=${condition}`);
    const ingredientsList = RecipeDbData.data.data;
   
    if(ingredientsList.length > 0){
      const updatedIngredientsList = [];
      for (const i of ingredientsList) {
      
          const condition = `date = '${kit.date}' AND session = '${kit.session}' AND menu_item_id = '${kit.menu_item_id}' AND batch = '${kit.batch}' AND ingredient_id = '${i.ingredient_id}'`;
          // console.log(condition);
          const response = await axios.get( `${baseUrl}/comman/list?table=item_requirement_detail&condition=${condition}`);
          
          let net_issued_qty;
          let net_issue_status;
          if(response.data.data.length > 0){
            net_issued_qty = response.data.data[0].net_issued_qty;
            net_issue_status = response.data.data[0].issue_net_status;
            // console.log(response.data.data);
          }

          
          updatedIngredientsList.push({
            ...i,
            net_issued_qty: net_issued_qty ?? 0,
            diff_qty : ((net_issued_qty ?? 0) - (i?.required_qty ?? 0)).toFixed(2),
            net_issue_status: net_issue_status ?? 1,
          });
        };
      // setSelectedItem(item);
      // console.log(updatedIngredientsList)
      setLoading(false);
      setItemIngredientList(updatedIngredientsList);
    }
  }

  const getKitList = async () => {
    setLoading(true);
    // let condition = `requirement_status = 2 AND ingredient_added = 2`;
    let condition = `1`;
    if(fromDate == toDate){
        condition += ` AND date = '${fromDate}' `;
    }else if(fromDate && toDate){
        
      condition += ` AND (date >= '${fromDate}' AND date  <= '${toDate}') `;
    }
    if(session?.value && session?.value != "undefined"){
      condition += ` AND session = '${session?.value}'`;
    }
    if(item?.value && item?.value != "undefined"){
      condition += ` AND menu_item_id = '${item?.value}'`;
    }
    if(issueStatus.length > 0 && issueStatus != "undefined"){

      const hasAll = issueStatus.some((status) => status?.value == 0);

      if(!hasAll){
        condition += `AND (`;
  
        issueStatus.map((status, index) => {
          condition += ` main_store_issue_status = '${status?.value}' `;
          if((index + 1)  != issueStatus.length){
            condition += `OR `;
          }
        })
  
        condition += ` )`
      }
    }
    
        const response = await axios.get(`${baseUrl}/comman/list?table=item_requirement_master&condition=${condition}`)
        const kitList = response.data.data;
        const updatedKitList = await Promise.all(
          kitList.map(async (kit) => {
            const condition = `pr.menu_item_id='${kit.menu_item_id}' AND pr.date = '${kit.date}' AND pr.session='${kit.session}' AND pr.batch = '${kit.batch}' AND im.store = 1`;
            const RecipeDbData = await axios.get(`${baseUrl}/main_store_issue/ingredient_list?condition=${condition}`);
            const ingredientsList = RecipeDbData.data.data;
      
            // Return item only if ingredientsList is not empty
            if (ingredientsList && ingredientsList.length > 0) {
              return kit;
            }
      
            // Return null or undefined if the ingredientsList is empty
            return null;
          })
        );
    
        const filteredKitList = updatedKitList.filter((item) => item !== null);
        setKitList(filteredKitList);
        console.log(filteredKitList);
        setLoading(false);
}
  // const getRequirementList = async() => {
  //   // console.log(issueStatus);
  //   const response = await axios.post(`${baseUrl}/issue/list`,
  //     { from_date : fromDate,
  //       to_date :toDate,
  //       session :session?.value,
  //       item : item?.value,
  //       issue_status: issueStatus?.value
       
  //   });
  //   setRequirementList(response.data.data);
  // }

  
  // const handleSubmit = async() => {
   
  //   const response = await axios.post(`${baseUrl}/requirement/status_update`,
  //     { selectedRows : selectedRows,
  //       status: 2});

  //     toast.success("Data successfully saved!");
  //     getRequirementList();
  // };

//   const handleToggleSelect = (index) => {
//     const updatedSelectedRows = selectedRows.includes(requirementList[index])
//         ? selectedRows.filter(item => item !== requirementList[index]) // Deselect
//         : [...selectedRows, requirementList[index]]; // Select

//    setSelectedRows(updatedSelectedRows);
    

// };
// const handleSwitchSelectAll = () => {
//   if (selectAll) {
//       setSelectedRows([]);
//   } else {
//       setSelectedRows(requirementList.filter((item) => item.issue_status == 1)); // Select all rows
//   }
//   setSelectAll(!selectAll);
// };

  const clearItem = () => {
    setActiveIndex(null)
    setSelectedItem(null);
    setItemIngredientList(null);
    getKitList()
  };

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
    setKit(kitList.find((item, i) => i == index))
  };
  

  const handleInputChange = (index) => (e) => {
    const { name, value } = e.target;
    
    setItemIngredientList((prevFormData) => {
      const updatedFormData = [...prevFormData];
      if(name === "net_issued_qty"){
        
        const required_qty = updatedFormData[index].required_qty;
        const diff_qty = (value - required_qty).toFixed(2);
        updatedFormData[index] = { ...updatedFormData[index], [name]: value, diff_qty : diff_qty };
      }else{
        updatedFormData[index] = { ...updatedFormData[index], [name]: value };
      }
      return updatedFormData;
    });
  };

  const handleSave = async() => {
    if(itemIngredientList.filter(item => item?.required_qty > 0).some(item => item.ingredient_id === null)){
      alert("One or more items have an empty ingredient value.");
      return
    }
    const issueCondition = itemIngredientList.filter(item => item?.required_qty > 0).some(item => 
      item.issue_status !== 3 && item.net_issued_qty > item.required_qty
    );

    if(issueCondition){
      const commonline = `ની ઇસ્યુ જથ્થો જરૂરી જથ્થો કરતાં વધુ છે.`
      let confirmMessage = ``

      itemIngredientList.filter(item => item?.required_qty > 0).forEach(item => {
        if(item.issue_status !== 3 && item.net_issued_qty > item.required_qty){
          confirmMessage += `${item.ingredient} ${commonline}\n`
        }
      })

      confirmMessage += `શું તમે આગળ વધવા માંગો છો?`
      const confirmed = window.confirm(confirmMessage)
      if(!confirmed) return
    }
    try{
        
        // console.log(itemIngredientList,kit)
      const response = await axios.post(`${baseUrl}/main_store_issue/ingredient_update`,
        { ingredientsList : itemIngredientList.filter(item => item?.required_qty > 0),
          item: kit});
          toast.success("Item added successfully!");
          clearItem()
    }catch (error) {
      toast.error("Failed to add item. Please try again.");
    }
    
  }

  const handleExport = async(e) => {
    e.preventDefault();
    try{
      const headerRow = ["Ingredient", "Required Qty", "Issue Qty", "Diff Qty", "Issue Status"];
     
      const csv = [headerRow].concat(
        itemIngredientList.map(item => {
             // Replace status values with "active" or "inactive"
            const status = item.net_issue_status == 3 ? "Issued" : (item.net_issue_status == 2 ? "Partly Issued" : "Pending");
             // Create an array of values, including the modified status
             return [item.ingredient, item.required_qty,item.net_issued_qty, item.diff_qty, status].join(',');
           })).join('\n');
     
           // Create a Blob containing the CSV data
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      
        // Trigger the file download using file-saver
        saveAs(blob, 'main_store_issue.csv');
    }catch (error) {
      toast.error("Failed to export item. Please try again.");
    }
  }


  const handleAddNewIngredientRow = ()=> {
    setItemIngredientList([...itemIngredientList,{
      ingredient:'', 
      ingredient_obj:{},
      ingredient_id:null, 
      unit:null, 
      unitname:'', 
      wastage:0,
      gross_issued_qty:0, 
      // net_issued_qty:0, 
      gross_required_qty:0, 
      required_qty:0, 
      issue_status:1,
      isNew: true 
    }])
  }

  const handleNewIngredientChange = (selectedOptions,index) => {
    // setNewIngredient((prev)=>({...prev,ingredient:ingredient}))
    // console.log(selectedOptions)
    const updatedList =   [...itemIngredientList]
    updatedList[index].ingredient_obj = selectedOptions
    updatedList[index].ingredient = selectedOptions.ingredient
    updatedList[index].ingredient_id = selectedOptions.value
    updatedList[index].unit = selectedOptions.unit
    updatedList[index].unitname = selectedOptions.unitname
    // console.log(updatedList[index])
    setItemIngredientList(updatedList)
  }

  return (
    <div className="p-4">
      {loading && <Loader/>}
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
          Main Store Issue
        </h1>
        
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <MainRequirementFilter handleFilterChange={handelFilterChange} page="issue" />
      </div>

      <div>
        {kitList.map((item,index) => (
          <div key={index} className="border-b mt-4">
            <div
            // className="flex justify-between items-center p-4 cursor-pointer accordion-title bg-orange-400 border rounded"
className={`flex justify-between items-center p-4 cursor-pointer accordion-title border rounded 
    ${item.main_store_issue_status == 1 ? 'bg-orange-400' : 
    (item.main_store_issue_status == 2 ? 'bg-yellow-400' : 'bg-green-400')}`}

            onClick={() => handleClick(index)}>
            <span>{`${item.kit_no} (${item.main_store_issue_status == 1 ? 'Pending issue' : (item.main_store_issue_status == 2 ? "Partly Issued" : "Issued")  })`}</span>
            {index === activeIndex ? (
              <ChevronUpIcon className="w-6 h-6" />
            ) : (
              <ChevronDownIcon className="w-6 h-6" />
            )}
            </div>
            {index === activeIndex && (
                <div className="space-y-2 p-4 ">
                <div>
                  <table className="w-full mt-10 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr> 
                        <th scope="col" className="px-2 py-1 border border-black text-center w-60 ">Ingredient</th>
                        <th scope="col" className="px-2 py-1 border border-black text-center ">Required Qty</th>
                        <th scope="col" className="px-2 py-1 border border-black text-center ">Issue Qty</th>
                        <th scope="col" className="px-2 py-1 border border-black text-center ">Diff Qty</th>
                        <th scope="col" className="px-2 py-1 border border-black text-center ">Issue Status</th>
                      </tr>
                    </thead>
                    <tbody>
                    {itemIngredientList?.filter(item => item?.required_qty > 0)?.map((item, index) =>(
                                <tr key={index} className="border border-black-b">
                                  {item.isNew ? (<td className="px-2 py-1 border border-black text-center"><Select
                                      // isMulti
                                      classNamePrefix="select"
                                      className='basic-multi-select'
                                      value={item.ingredient_obj}
                                      name="ingredient"
                                      onChange={(selectedOptions) => handleNewIngredientChange(selectedOptions,index)}
                                      options={ingredientList.map((item) => ({
                                          value: item.id,
                                          label: `${item.ingredient} (${item.eng_name})`,
                                          unit:item.unit?.id,
                                          unitname:item.unit?.name,
                                          ingredient: `${item.ingredient}`,
                                          eng_name: item.eng_name,
                                        }))}
                                      placeholder="Ingredient List"
                                      filterOption={(option, inputValue) => {
                                          return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                                      }}
                                  /></td>) :  <td className="px-2 py-1 border border-black text-center">{item.ingredient}</td>}
                                  
                                  <td className="px-2 py-1 border border-black text-center">{parseFloat(item?.required_qty || 0).toFixed(2)}</td>

                                  
                                  <td className="px-2 py-1 border border-black text-center"><input type="number"
                                      id="net_issued_qty" 
                                      name="net_issued_qty" 
                                      disabled={item.issue_status === 2}
                                      value={item?.net_issued_qty} 
                                      onWheel={(e) => e.target.blur()}
                                      onChange={handleInputChange(index)}
                                      onKeyDown={(e) => {
                                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                          e.preventDefault(); // Disable up and down arrow keys
                                        }
                                      }} 
                                      className="px-2 py-1 border rounded-md w-20 bg-orange-50"
                                    /></td>
                                  <td className={`px-2 py-1 border border-black text-center ${item.diff_qty < 0 ? 'bg-red-300 text-black' : ''}`}>{parseFloat(item?.diff_qty || 0).toFixed(2)}</td>
                                  <td className="px-2 py-1 border border-black text-center">{item.net_issue_status == 3 ? "Issued" : (item.net_issue_status == 2 ? "Partly Issue" : "Pending")}</td>
                                </tr>
                              ))}
                    </tbody>
                  </table>
                </div>
                
                  <div className="flex space-y-2 items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                          {kit.issue_status !==3 &&(<div>
                          {/* <button
                              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              disabled={item.issue_status == 3}
                              onClick={() => handleSumitForOrder()}
                            >
                              Submit for Issue
                            </button> */}
                            <button
                              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              disabled={kit.issue_status == 3}
                              onClick={() => handleSave()}
                            >
                              Submit for Issue
                            </button>
                            <button
                              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              disabled={kit.issue_status === 3}
                              onClick={() =>{handleAddNewIngredientRow()}}
                            >
                              Add Ingredient
                            </button>
                            </div>)}
                            <button
            onClick={handleExport}
            className="px-4 py-2 bg-green-500 text-white rounded-md mr-4 hover:bg-rose-600 focus:outline-none focus:ring focus:ring-rose-200"
        >
          Export
        </button>
                  </div>
                </div>)}
          </div>
        ))}
      </div>

      
    </div>
  );
}

export default IssueList;
